<template>
  <modal-layout @close="$emit('close')" title="Ошибка">
    <div class="p-3">
      <template v-if="errorsList">
        <h3 class="main-title" v-for="(item, index) in errorsList" :key="index">
          {{item}}
        </h3>
      </template>
      <h3 class="main-title" v-if="singleError">
        {{singleError}}
      </h3>
    </div>
    <button class="primary-btn bottom-page-btn" @click="$emit('close')">Закрыть</button>
  </modal-layout>
</template>

<script>

export default {
  name: "RegistrationErrorModal",
  props: {
    errorsList: null,
    singleError: String
  }
}
</script>

<style lang="scss" >

</style>
